<template>

    <v-btn class="ma-4" color="primary"  small v-show="!$vuetify.breakpoint.smAndDown" @click="downloadCSV" >
                <v-icon left>mdi-download</v-icon>

                Exportar datos
            </v-btn>


</template>

<script>
import { collection, getDocs, query, getFirestore } from 'firebase/firestore';
import moment from 'moment';

import { logAuditEvent } from '@/error/audit.js';
///        await logAuditEvent('update',this.$store.state.Auth.token.claims.user_id,`User ${this.user.id} re-enabled`)


export default {
    name: 'DownloadUsersData',
    methods: {
        async downloadCSV() {
            try {

                let confirm = await this.$confirm(
                "¿Estás seguro que deseas exportar los datos de los usuarios?",
                    {
                        title: "Exportar datos de usuarios",
                        buttonTrueText: "CONFIRMAR",
                        buttonFalseText: "CANCELAR"
                    }
                );

                if (!confirm) {
                    return;
                }


                this.loading = true;
                const db = getFirestore();
                const usersQuery = query(collection(db, 'users'));
                const disabledUsersQuery = query(collection(db, 'disabledUsers'));
                const paymentsQuery = query(collection(db, 'payments'));

                const [usersSnapshot, disabledUsersSnapshot, paymentsSnapshot] = await Promise.all([
                    getDocs(usersQuery),
                    getDocs(disabledUsersQuery),
                    getDocs(paymentsQuery)
                ]);

                const users = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const disabledUsers = disabledUsersSnapshot.docs.map(doc => ({
                    ...doc.data()
                }));

                const payments = paymentsSnapshot.docs.map(doc => ({
                    ...doc.data()
                }));

                let combinedData = users.map(user => {
                    let disabledUser = disabledUsers.filter(dUser => dUser.id === user.id);
                    let disabledDate = '';
                    if (disabledUser.length > 0) {
                        disabledUser = disabledUser.sort((a, b) => b.date - a.date)[0];
                    }
                    if (disabledUser && disabledUser.date) {
                        if (typeof disabledUser.date.toDate === 'function') {
                            disabledDate = moment(disabledUser.date.toDate()).format('YYYY-MM-DD');
                        } else {
                            disabledDate = moment(new Date(disabledUser.date.seconds * 1000)).format('YYYY-MM-DD');
                        }
                    }

                    let endOfSubscription = '';
                    if (user.endOfSubscription) {
                        if (typeof user.endOfSubscription.toDate === 'function') {
                            endOfSubscription = moment(user.endOfSubscription.toDate()).format('YYYY-MM-DD');
                        } else {
                            endOfSubscription = moment(new Date(user.endOfSubscription.seconds * 1000)).format('YYYY-MM-DD');
                        }
                    }
                    let plan = ''
                    if (user.type === 'paselibre') {
                        endOfSubscription = '';
                        plan = 'Paselibre';
                    }

                    if (user.plan) {
                        plan = parseInt(user.plan);
                        if (plan == 0) {
                            plan = 'Libre'
                        } else {
                            plan = plan
                        }
                    }


                    let paymentsByUser = payments.filter(payment => payment.id === user.id);
                    if (paymentsByUser.length > 0) {
                        paymentsByUser = paymentsByUser.sort((a, b) => b.date - a.date);
                        paymentsByUser = paymentsByUser[0].months
                    } else {
                        paymentsByUser = 'Sin pagos';
                    }



                    return {
                        ...user,
                        months: paymentsByUser,
                        plan,
                        birthDate: user.birthDate || '',
                        endOfSubscription,
                        disabledDate,
                        emergencyContact: user.emergencyContact ? user.emergencyContact.replace(/,/g, ' ') : '',
                        observation: user.observation ? user.observation.replace(/,/g, ' ') : '',
                        medicalService: user.medicalService ? user.medicalService.replace(/,/g, ' ') : '',

                        reason: disabledUser && disabledUser.reason ? disabledUser.reason.replace(/,/g, ' ') : '',
                        // replace all',' with ' ' in the address
                        address: user.address ? user.address.replace(/,/g, ' ') : '',
                    };
                });

                // sort users by user.disabled
                combinedData = combinedData.sort((a, b) => {
                    if (a.disabled && b.disabled) {
                        return a.disabled - b.disabled;
                    } else if (a.disabled) {
                        return -1;
                    } else if (b.disabled) {
                        return 1;
                    }
                    return 0;
                });

                // remove type superuser
                combinedData = combinedData.filter(user => user.type !== 'superuser');

                combinedData = combinedData.map(userNow => {
                    let user = { ...userNow };
                    ['country', 'id', 'lesions', 'details', 'musicalTastes', 'injuries', 'objectives', 'routines', 'auth', 'healthConditions', 'createdAt', 'company', 'expired', 'musicalPreference', 'licenseCount'].forEach(key => {
                        delete user[key];
                    });
                    return user;
                });

                // Define the column order
                const columnOrder = ['civilId', 'displayName', 'plan', 'months', 'email', 'phoneNumber', 'address', 'birthDate', 'medicalService', 'licensePaused', 'observation', 'endOfSubscription', 'disabled', 'disabledDate', 'reason'];

                // Reorder the data based on the specified columns
                combinedData = this.reorderColumns(combinedData, columnOrder);

                const csvContent = this.convertToCSV(combinedData);
                this.downloadFile(csvContent, 'users_data.csv');
            } catch (error) {
                console.error('Error fetching data:', error);

            } finally {
                this.loading = false;
            }

        },
        reorderColumns(data, columnOrder) {
            return data.map(item => {
                const reorderedItem = {};
                columnOrder.forEach(column => {
                    reorderedItem[column] = item[column] || '';
                });
                return reorderedItem;
            });
        },
        convertToCSV(data) {
            if (data.length === 0) {
                return '';
            }

            let headers = Object.keys(data[0]);
            let headerRow = headers.join(',');

            let headerRowTranslatedToSpanish = [
                'Documento de identidad',
                'Nombre completo',
                'Dias por semana',
                'Ultimas suscripcion pagada',
                'Correo',
                'Teléfono',
                'Dirección',
                'Fecha de nacimiento',
                'Servicio médico',
                'Licencia pausada',
                'Observación',
                'Fin de suscripción',
                'Dado de baja',
                'Fecha de baja',
                'Razón de baja',
            ];

            headerRow = headerRowTranslatedToSpanish.join(',');



            const rows = data.map(row =>
                headers.map(header => `${row[header] || ''}`).join(',')
            ).join('\n');

            return `${headerRow}\n${rows}`;
        },
        downloadFile(content, filename) {
            const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                logAuditEvent('export', this.$store.state.Auth.token.claims.user_id, `Users data exported`);

            }
        },
    },
    data() {
        return {
            loading: false
        };
    },
};
</script>
