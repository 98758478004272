<template>
  <v-container fluid class="pa-0">
  
    <v-toolbar flat>
    <v-text-field  filled rounded single-line dense v-model="search" hide-details="" class=" rounded-lg" 
      label="Buscar" append-icon="mdi-magnify">
    </v-text-field>

    </v-toolbar>

          <v-divider></v-divider>


  <v-data-table class="elevation-0 rounded-0" style="width:100%" :headers="headers" :items="data" :search="search" :loading="loading"
            @click:row="rowClick" disable-pagination hide-default-footer fixed-header
            :disable-sort="$store.state.Auth.token.claims.type != 'superuser' && $store.state.Auth.token.claims.type != 'admin'"
            mobile-breakpoint="0" sort-by="expired">

            <template v-slot:no-data v-if="$store.state.Auth.token.claims.type == 'entrenador'">
              <h3 class="ma-6 subtitle-1">{{ search && search.length > 4 ? 'No se encontraron resultados' : 'Ingrese una
                cedula para buscar' }}</h3>


            </template>

            <template v-slot:no-results v-if="$store.state.Auth.token.claims.type == 'entrenador'">
              <h3 class="ma-6 subtitle-1">{{ search && search.length > 4 ? 'No se encontraron resultados' : 'Ingrese una
                cedula para buscar' }}</h3>
            </template>

            <!-- 
              <template v-slot:item.civilId="{ item }">
                {{ validate_ci(item.civilId) ? item.civilId : item.civilId + ' CI INVALIDA'}}
              </template> -->


            <template v-slot:item.img="{ item }">
              <v-avatar size="40"  class="mr-2">
                <UserImage  :user="item" xsmall />
              </v-avatar>
            </template>

            
            <template v-slot:item.displayName="{ item }">
    

              {{ item.displayName }}



            </template>




            <template v-slot:item.type="{ item }"
              v-if="$store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin'">
              <v-chip outlined :color="getColor(item.type)" dark small>
                {{ item.type ? item.type.toUpperCase() : '' }}
              </v-chip>
            </template>

            <template v-slot:item.phoneNumber="{ item }">
              {{ item.phoneNumber ? item.phoneNumber.replace(/\+598|\s/g, '') : '' }}
            </template>


            <template v-slot:item.endOfSubscription="{ item }">

              <v-chip :color="getVencimiento(item) == 'DEBE CUOTA' ? 'red' : 'green'" dark
                v-if="item.endOfSubscription">
                {{ getVencimiento(item) }}
              </v-chip>


            </template>


            <template v-slot:item.plan="{ item }">

              <span v-if="item.plan == 0">
                Libre
              </span>
            
              <span v-else>
                {{ item.plan }}

              </span>


            </template>



            <template v-slot:item.injuries="{ item }">

              <v-chip color="error" small outlined
                v-if="item.injuries && item.injuries.length > 0 && item.injuries.filter(i => !i.recuperado).length > 0">
                <v-icon left small>mdi-alert</v-icon>
                {{ item.injuries.filter(i => !i.recuperado).length }} Actuales
              </v-chip>

              <v-chip color="success" small outlined
                v-if="item.injuries && item.injuries.filter(i => i.recuperado).length">
                <v-icon left small>mdi-check</v-icon>
                {{ item.injuries.filter(i => i.recuperado).length }} Recuperadas
              </v-chip>


            </template>








            <template v-slot:item.expired="{ item }">
              <v-chip v-if="item.licensePaused == true" color="primary darken-4" dark>
                <v-icon>mdi-pause</v-icon>
                Licencia activa


              </v-chip>
              <v-chip v-else-if="item.endOfSubscription" :color="item.expired && item.expired <= 0 ? 'red' : 'success darken-1'"
                dark>
                 {{item.expired>0?'+':''}}{{ item.expired }} </v-chip>

                  <v-chip dark color="warning darken-3"
                    v-else-if="!item.endOfSubscription && ['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(item.type)">
                    <v-icon left small>mdi-alert</v-icon>

                    DEBE PRIMER CUOTA
                  </v-chip>




            </template>

          </v-data-table>

  </v-container>



</template>

<script>
import TableItem from "@/components/TableItem.vue";

import { mask } from 'vue-the-mask'
import moment from 'moment'
import UserImage from '@/components/profile/UserImage.vue';

export default {
  directives: { mask },
  data() {
    return {
      search: ""
    }
  },
  components: {
    TableItem, UserImage
  },

  props: {
    data: Array,
    icon: String,
    title: String,
    subtitle: String,
    subtitleIcon: String,
    loading: Boolean,
    acceptCompanies: Boolean,
    companies: Array,
    headers: Array
  },
  methods: {
    rowClick(e) {
      this.$emit('save', e)
    },
    emitSearch() {
      if (this.search.length >= 4)
        this.$emit('search', this.search)
    },
    emitAdd() {
      this.$emit('add2', this.search)
    },
    getColor(type) {
      switch (type) {
        case 'superuser':
          return 'black'
        case 'admin':
          return 'orange'
        case 'usuario':
          return 'green';
        case 'entrenador':
          return 'blue';
        case 'paselibre':
          return 'cyan';
        case 'gratis':
          return 'green darken-2';
        default:
          return 'grey'
      }
    },
    getVencimiento(item) {
      let user = item

      if (user.endOfSubscription && ['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(user.type)) {
        let today = new Date();
        let endOfSubscription = user.endOfSubscription.toDate();
        if (today > endOfSubscription) {
          return "DEBE CUOTA"
        }
        else {
          return endOfSubscription.getDate() + '/' + (endOfSubscription.getMonth() + 1) + '/' + endOfSubscription.getFullYear();

        }
      } else {

        return '';
      }


    },
    validation_digit(ci) {
      var a = 0;
      var i = 0;
      if (ci.length <= 6) {
        for (i = ci.length; i < 7; i++) {
          ci = '0' + ci;
        }
      }
      for (i = 0; i < 7; i++) {
        a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
      }
      if (a % 10 === 0) {
        return 0;
      } else {
        return 10 - a % 10;
      }
    }

    , validate_ci(ci) {
      ci = this.clean_ci(ci);
      var dig = ci[ci.length - 1];
      ci = ci.replace(/[0-9]$/, '');
      return (dig == this.validation_digit(ci));
    }

    , random_ci() {
      var ci = Math.floor(Math.random() * 10000000).toString();
      ci = ci.substring(0, 7) + validation_digit(ci);
      return ci;
    }

    , clean_ci(ci) {
      return ci.replace(/\D/g, '');
    }

  },
  watch: {
    search() {

      if (this.$store.state.Auth.token.claims.type && (this.$store.state.Auth.token.claims.type == 'entrenador' && this.search.isNumeric() && this.search.length >= 4)) {
        this.emitSearch()
      }

    },
  },

}
</script>
